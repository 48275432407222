import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Quote } from '../data/quotes';
import { QuoteForm } from '../components/quotes/QuoteForm';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { ArrowLeft, Send, Download, Copy, Eye } from 'lucide-react';

export default function CreateQuotePage() {
  const navigate = useNavigate();

  const handleSubmit = (data: Partial<Quote>) => {
    // Handle quote creation
    navigate('/quotes');
  };

  return (
    <div className="min-h-screen bg-slate-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="mb-6 flex items-center justify-between">
          <div>
            <button
              onClick={() => navigate('/quotes')}
              className="inline-flex items-center text-emerald-600 hover:text-emerald-700 mb-2"
            >
              <ArrowLeft className="h-4 w-4 mr-2" />
              Back to Quotes
            </button>
            <Breadcrumbs />
          </div>
          <div className="flex gap-3">
            <button className="inline-flex items-center px-4 py-2 text-slate-600 hover:text-slate-800">
              <Eye className="h-4 w-4 mr-2" />
              Preview
            </button>
            <button className="inline-flex items-center px-4 py-2 text-slate-600 hover:text-slate-800">
              <Copy className="h-4 w-4 mr-2" />
              Duplicate
            </button>
            <button className="inline-flex items-center px-4 py-2 text-slate-600 hover:text-slate-800">
              <Download className="h-4 w-4 mr-2" />
              Download PDF
            </button>
            <button className="inline-flex items-center px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700">
              <Send className="h-4 w-4 mr-2" />
              Send Quote
            </button>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-6">
          <div className="col-span-2">
            <div className="bg-white rounded-lg shadow-sm border border-emerald-100 p-6">
              <QuoteForm
                onSubmit={handleSubmit}
                onCancel={() => navigate('/quotes')}
              />
            </div>
          </div>

          <div className="space-y-6">
            {/* Quick Actions */}
            <div className="bg-white rounded-lg shadow-sm border border-emerald-100 p-6">
              <h3 className="text-lg font-semibold text-slate-800 mb-4">Quick Actions</h3>
              <div className="space-y-3">
                <button className="w-full text-left px-4 py-3 rounded-lg hover:bg-slate-50">
                  <p className="font-medium text-slate-800">Save as Draft</p>
                  <p className="text-sm text-slate-600">Continue editing later</p>
                </button>
                <button className="w-full text-left px-4 py-3 rounded-lg hover:bg-slate-50">
                  <p className="font-medium text-slate-800">Save as Template</p>
                  <p className="text-sm text-slate-600">Reuse for future quotes</p>
                </button>
                <button className="w-full text-left px-4 py-3 rounded-lg hover:bg-slate-50">
                  <p className="font-medium text-slate-800">Convert to Job</p>
                  <p className="text-sm text-slate-600">Create job from quote</p>
                </button>
              </div>
            </div>

            {/* Recent Activity */}
            <div className="bg-white rounded-lg shadow-sm border border-emerald-100 p-6">
              <h3 className="text-lg font-semibold text-slate-800 mb-4">Recent Activity</h3>
              <div className="space-y-4">
                <div className="flex items-start gap-3">
                  <div className="w-2 h-2 rounded-full bg-emerald-500 mt-2" />
                  <div>
                    <p className="text-sm text-slate-800">Quote #1234 accepted</p>
                    <p className="text-xs text-slate-500">2 hours ago</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <div className="w-2 h-2 rounded-full bg-amber-500 mt-2" />
                  <div>
                    <p className="text-sm text-slate-800">Quote #1233 viewed</p>
                    <p className="text-xs text-slate-500">5 hours ago</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Templates */}
            <div className="bg-white rounded-lg shadow-sm border border-emerald-100 p-6">
              <h3 className="text-lg font-semibold text-slate-800 mb-4">Quote Templates</h3>
              <div className="space-y-3">
                <button className="w-full text-left px-4 py-3 rounded-lg hover:bg-slate-50">
                  <p className="font-medium text-slate-800">Basic Service Quote</p>
                  <p className="text-sm text-slate-600">Last used 2 days ago</p>
                </button>
                <button className="w-full text-left px-4 py-3 rounded-lg hover:bg-slate-50">
                  <p className="font-medium text-slate-800">Premium Package</p>
                  <p className="text-sm text-slate-600">Last used 1 week ago</p>
                </button>
                <button className="w-full text-left px-4 py-3 rounded-lg hover:bg-slate-50">
                  <p className="font-medium text-slate-800">Maintenance Contract</p>
                  <p className="text-sm text-slate-600">Last used 2 weeks ago</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}