import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Phone, Mail, Search, Filter, Plus, Building2, MapPin, Tags, Activity, Clock, LayoutGrid, List, Trash2, Pencil } from 'lucide-react';
import { clientTags, clientActivities } from '../data/client-activity';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { useQuery } from '@apollo/client';
import { GET_CLIENTS_QUERY } from '@/graphql/queries';
import { supabase } from '@/lib/supabase';
import { DeleteClientButton } from '@/components/clients/DeleteClientButton';

type SortOption = 'name' | 'recent' | 'oldest';
type ViewMode = 'grid' | 'list';
type Client = {
  id: string;
  full_name: string;
  email_address: string;
  phone_number: string;
  address: string;
  image_path: string;
  tags?: string[];
};


function ClientsPage() {
  const [companyId, setCompanyId] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [clients, setClients] = useState<Client[]>([]);
  const [sortBy, setSortBy] = useState<SortOption>('name');
  const [showFilters, setShowFilters] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [viewMode, setViewMode] = useState<ViewMode>('grid');
  const [loadingSession, setLoadingSession] = useState(true);  // track the loading state for session data

  useEffect(() => {
    const fetchUserData = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      const user = session?.user;
      if (user) {
        setCompanyId(user.user_metadata?.company_id);  // Set companyId after fetching session
      }
      setLoadingSession(false);  // Set loading to false once session data is fetched
    };

    fetchUserData();
  }, []);

  const { loading, error, data, refetch} = useQuery<{ clientsCollection: { edges: { node: Client }[] } }>(
    GET_CLIENTS_QUERY, {
      variables: { companyId },
      skip: loadingSession || !companyId,
    }
  );

  const fetchClients = async () => {
    try {
      if (data?.clientsCollection?.edges) {
        const fetchedClients = data.clientsCollection.edges.map(({ node }) => node);
        setClients(fetchedClients);
      }
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const refreshClients = async () => {
    try {
      const { data: refreshedData } = await refetch();
      console.log('Clients refreshed:', refreshedData.clientsCollection);
      if (refreshedData?.clientsCollection?.edges) {
        const fetchedClients = refreshedData.clientsCollection.edges.map(({ node }) => node);
        setClients(fetchedClients);
      }
    } catch (error) {
      console.error('Error refreshing clients:', error);
    }
  };

  useEffect(() => {
    fetchClients();
  }, [data]);

  const filteredClients = clients.filter(client =>
    (client.full_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    client.email_address.toLowerCase().includes(searchQuery.toLowerCase()) ||
    client.phone_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
    client.address.toLowerCase().includes(searchQuery.toLowerCase())) &&
    (selectedTags.length === 0 || selectedTags.some(tag => 
      clientTags.find(t => t.id === tag)?.name.toLowerCase()
    ))
  );

  const sortedClients = [...filteredClients].sort((a, b) => {
    switch (sortBy) {
      case 'name':
        return a.full_name.localeCompare(b.full_name);
      // case 'recent':
      //   return new Date(b.clientSince).getTime() - new Date(a.clientSince).getTime();
      // case 'oldest':
      //   return new Date(a.clientSince).getTime() - new Date(b.clientSince).getTime();
      default:
        return 0;
    }
  });

  return (
    <div className="p-6">
      <div className="mb-6">
        <Breadcrumbs />
        <div className="mt-4 flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-bold text-slate-800">Clients</h1>
            <p className="text-slate-600">Manage your client relationships</p>
          </div>
          <button
            onClick={() => navigate('/clients/new')}
            className="inline-flex items-center px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
          >
            <Plus className="h-5 w-5 mr-2" />
            Add Client
          </button>
        </div>
      </div>

      <div className="mb-6 space-y-4">
        <div className="flex gap-4">
          <div className="relative flex-1">
            <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-slate-400" />
            <input
              type="text"
              placeholder="Search clients..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full max-w-md rounded-lg border border-slate-200 pl-9 pr-4 py-2 focus:border-purple-500 focus:ring-1 focus:ring-purple-500"
            />
          </div>
          <button
            onClick={() => setShowFilters(!showFilters)}
            className={`inline-flex items-center px-3 py-2 rounded-lg border ${
              showFilters
                ? 'border-purple-200 bg-purple-50 text-purple-700'
                : 'border-slate-200 hover:border-purple-200 hover:bg-purple-50 text-slate-600 hover:text-purple-700'
            }`}
          >
            <Filter className="h-4 w-4 mr-2" />
            Filters
          </button>
          <div className="flex gap-2 border border-slate-200 rounded-lg p-1">
            <button
              onClick={() => setViewMode('grid')}
              className={`p-2 rounded ${
                viewMode === 'grid'
                  ? 'bg-purple-50 text-purple-700'
                  : 'text-slate-600 hover:text-purple-700 hover:bg-purple-50'
              }`}
            >
              <LayoutGrid className="h-4 w-4" />
            </button>
            <button
              onClick={() => setViewMode('list')}
              className={`p-2 rounded ${
                viewMode === 'list'
                  ? 'bg-purple-50 text-purple-700'
                  : 'text-slate-600 hover:text-purple-700 hover:bg-purple-50'
              }`}
            >
              <List className="h-4 w-4" />
            </button>
          </div>
        </div>

        {showFilters && (
          <div className="grid grid-cols-2 gap-4 p-4 bg-white rounded-lg border border-slate-200">
            <div>
              <label className="block text-sm font-medium text-slate-700 mb-1">
                Sort by
              </label>
              <select
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value as SortOption)}
                className="w-full rounded-lg border border-slate-300 px-3 py-2"
              >
                <option value="name">Name</option>
                <option value="recent">Most Recent</option>
                <option value="oldest">Oldest First</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-slate-700 mb-1">
                Tags
              </label>
              <div className="flex flex-wrap gap-2">
                {clientTags.map((tag) => (
                  <button
                    key={tag.id}
                    onClick={() => setSelectedTags(prev =>
                      prev.includes(tag.id)
                        ? prev.filter(t => t !== tag.id)
                        : [...prev, tag.id]
                    )}
                    className={`px-3 py-1 rounded-full text-sm ${
                      selectedTags.includes(tag.id)
                        ? tag.color
                        : 'bg-slate-100 text-slate-600 hover:bg-slate-200'
                    }`}
                  >
                    {tag.name}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      {viewMode === 'grid' ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {sortedClients.map((client) => (
              <div className="bg-white rounded-lg shadow-sm border border-purple-100 p-6 hover:shadow-md transition-shadow">
              <div className="flex items-center mb-4">
                <img
                  src={client.image_path}
                  alt={client.full_name}
                  className="w-12 h-12 rounded-full object-cover"
                />
                <div className="ml-4">
                  <h3 className="font-semibold text-slate-800">{client.full_name}</h3>
                  <p className="text-sm text-purple-600">Client since </p>
                </div>
              </div>
              <div className="space-y-2">
                <div className="flex items-center text-sm text-slate-600">
                  <Phone className="h-4 w-4 mr-2" />
                  {client.phone_number}
                </div>
                <div className="flex items-center text-sm text-slate-600">
                  <Mail className="h-4 w-4 mr-2" />
                  {client.email_address}
                </div>
                <div className="flex items-center text-sm text-slate-600">
                  <MapPin className="h-4 w-4 mr-2" />
                  {client.address}
                </div>
              </div>  
              <div className="space-y-2 flex items-end">
                <DeleteClientButton
                  clientId={client.id}
                  clientName={client.full_name}
                  onDeleted={refreshClients}
                />
                <Link
                  key={client.id}
                  to={`/clients/${client.id}`}
                  className="ml-2 text-blue-600"
                >
                  <Pencil className="h-4 w-4" />
                </Link>

              </div>
              </div>
          ))}
        </div>
      ) : (
        <div className="bg-white rounded-lg shadow-sm border border-purple-100 overflow-hidden">
          <table className="min-w-full divide-y divide-purple-100">
            <thead className="bg-purple-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-slate-500 uppercase tracking-wider">Client</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-slate-500 uppercase tracking-wider">Contact</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-slate-500 uppercase tracking-wider">Address</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-slate-500 uppercase tracking-wider">Since</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-purple-100">
              {sortedClients.map((client) => (
                <tr
                  key={client.id}
                  onClick={() => navigate(`/clients/${client.id}`)}
                  className="hover:bg-purple-50 cursor-pointer"
                >
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      <img
                        src={client.image_path}
                        alt={client.full_name}
                        className="w-8 h-8 rounded-full object-cover"
                      />
                      <div className="ml-3">
                        <p className="font-medium text-slate-800">{client.full_name}</p>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="space-y-1">
                      <div className="flex items-center text-sm text-slate-600">
                        <Phone className="h-4 w-4 mr-2" />
                        {client.phone_number}
                      </div>
                      <div className="flex items-center text-sm text-slate-600">
                        <Mail className="h-4 w-4 mr-2" />
                        {client.email_address}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center text-sm text-slate-600">
                      <MapPin className="h-4 w-4 mr-2" />
                      {client.address}
                    </div>
                  </td>
                  <td className="px-6 py-4 text-sm text-slate-600">
                    {/* {client.clientSince} */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default ClientsPage;