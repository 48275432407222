import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeft, MapPin, Clock, DollarSign, User, Calendar, Save, Repeat, Phone, Mail } from 'lucide-react';
import { Job, JobStatus, RecurringType } from '../data/jobs';
import { clients } from '../data/clients';
import { services } from '../data/services';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { TipTapEditor } from '../components/editor/TipTapEditor';
import { FileUpload } from '../components/ui/file-upload';
import { TaskList } from '../components/jobs/TaskList';

export default function CreateJobPage() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<Partial<Job>>({
    clientId: '',
    service: '',
    date: new Date().toISOString().split('T')[0],
    time: '09:00',
    address: '',
    price: '',
    status: 'Scheduled',
    photo: '',
    notes: '',
    tasks: [],
    recurring: 'one-time'
  });
  const [mediaFile, setMediaFile] = useState<File | null>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Here you would typically save to your backend
    // For now, just navigate back to jobs list
    navigate('/jobs');
  };

  const selectedClient = clients.find(c => c.id === formData.clientId);

  return (
    <div className="p-6">
      <div className="mb-6">
        <Link to="/jobs" className="inline-flex items-center text-purple-600 hover:text-purple-700 mb-2">
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Jobs
        </Link>
        <Breadcrumbs />
        <div className="mt-4">
          <h1 className="text-2xl font-bold text-slate-800">Create New Job</h1>
          <p className="text-slate-600">Schedule a new service job</p>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2">
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Client Selection */}
            <div className="bg-white rounded-lg shadow-sm border border-purple-100">
              <div className="border-b border-purple-100 px-6 py-4 bg-purple-50">
                <div className="flex items-center gap-2">
                  <User className="h-5 w-5 text-purple-600" />
                  <h2 className="text-lg font-semibold text-slate-800">Client Information</h2>
                </div>
              </div>
              <div className="p-6">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-slate-700 mb-1">
                      Client
                    </label>
                    <select
                      value={formData.clientId}
                      onChange={(e) => {
                        const client = clients.find(c => c.id === e.target.value);
                        setFormData({
                          ...formData,
                          clientId: e.target.value,
                          address: client?.address || ''
                        });
                      }}
                      className="w-full rounded-lg border border-slate-300 px-3 py-2"
                      required
                    >
                      <option value="">Select a client</option>
                      {clients.map((client) => (
                        <option key={client.id} value={client.id}>
                          {client.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-slate-700 mb-1">
                      Service
                    </label>
                    <select
                      value={formData.service}
                      onChange={(e) => setFormData({ ...formData, service: e.target.value })}
                      className="w-full rounded-lg border border-slate-300 px-3 py-2"
                      required
                    >
                      <option value="">Select a service</option>
                      {services.map((service) => (
                        <option key={service.id} value={service.name}>
                          {service.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {selectedClient && (
                  <div className="mt-4 grid grid-cols-2 gap-4">
                    <div className="flex items-center text-sm text-slate-600">
                      <Phone className="h-4 w-4 mr-2" />
                      {selectedClient.phone}
                    </div>
                    <div className="flex items-center text-sm text-slate-600">
                      <Mail className="h-4 w-4 mr-2" />
                      {selectedClient.email}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Schedule */}
            <div className="bg-white rounded-lg shadow-sm border border-purple-100">
              <div className="border-b border-purple-100 px-6 py-4 bg-purple-50">
                <div className="flex items-center gap-2">
                  <Calendar className="h-5 w-5 text-purple-600" />
                  <h2 className="text-lg font-semibold text-slate-800">Schedule</h2>
                </div>
              </div>
              <div className="p-6">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-slate-700 mb-1">
                      Date
                    </label>
                    <input
                      type="date"
                      value={formData.date}
                      onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                      className="w-full rounded-lg border border-slate-300 px-3 py-2"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-slate-700 mb-1">
                      Time
                    </label>
                    <input
                      type="time"
                      value={formData.time}
                      onChange={(e) => setFormData({ ...formData, time: e.target.value })}
                      className="w-full rounded-lg border border-slate-300 px-3 py-2"
                      required
                    />
                  </div>
                </div>

                <div className="mt-4">
                  <label className="block text-sm font-medium text-slate-700 mb-1">
                    Recurring Schedule
                  </label>
                  <select
                    value={formData.recurring}
                    onChange={(e) => setFormData({ ...formData, recurring: e.target.value as RecurringType })}
                    className="w-full rounded-lg border border-slate-300 px-3 py-2"
                    required
                  >
                    <option value="one-time">One-time</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="quarterly">Quarterly</option>
                    <option value="yearly">Yearly</option>
                  </select>
                </div>
              </div>
            </div>

            {/* Location */}
            <div className="bg-white rounded-lg shadow-sm border border-purple-100">
              <div className="border-b border-purple-100 px-6 py-4 bg-purple-50">
                <div className="flex items-center gap-2">
                  <MapPin className="h-5 w-5 text-purple-600" />
                  <h2 className="text-lg font-semibold text-slate-800">Location</h2>
                </div>
              </div>
              <div className="p-6">
                <div>
                  <label className="block text-sm font-medium text-slate-700 mb-1">
                    Service Address
                  </label>
                  <input
                    type="text"
                    value={formData.address}
                    onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                    className="w-full rounded-lg border border-slate-300 px-3 py-2"
                    required
                  />
                </div>
              </div>
            </div>

            {/* Tasks */}
            <div className="bg-white rounded-lg shadow-sm border border-purple-100">
              <div className="border-b border-purple-100 px-6 py-4 bg-purple-50">
                <div className="flex items-center gap-2">
                  <Clock className="h-5 w-5 text-purple-600" />
                  <h2 className="text-lg font-semibold text-slate-800">Tasks</h2>
                </div>
              </div>
              <div className="p-6">
                <TaskList
                  tasks={formData.tasks || []}
                  onTasksChange={(tasks) => setFormData({ ...formData, tasks })}
                />
              </div>
            </div>

            {/* Notes */}
            <div className="bg-white rounded-lg shadow-sm border border-purple-100">
              <div className="border-b border-purple-100 px-6 py-4 bg-purple-50">
                <h2 className="text-lg font-semibold text-slate-800">Notes</h2>
              </div>
              <div className="p-6">
                <TipTapEditor
                  content={formData.notes || ''}
                  onChange={(content) => setFormData({ ...formData, notes: content })}
                />
              </div>
            </div>

            {/* Media */}
            <div className="bg-white rounded-lg shadow-sm border border-purple-100">
              <div className="border-b border-purple-100 px-6 py-4 bg-purple-50">
                <h2 className="text-lg font-semibold text-slate-800">Media</h2>
              </div>
              <div className="p-6">
                <FileUpload
                  onFileSelect={setMediaFile}
                  onRemove={() => setMediaFile(null)}
                  currentFile={formData.photo}
                  accept="image/*"
                  maxSize={5}
                />
              </div>
            </div>

            <div className="flex justify-end gap-3">
              <button
                type="button"
                onClick={() => navigate('/jobs')}
                className="px-4 py-2 text-slate-600 hover:text-slate-800"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="inline-flex items-center px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
              >
                <Save className="h-4 w-4 mr-2" />
                Create Job
              </button>
            </div>
          </form>
        </div>

        <div className="space-y-6">
          {/* Price */}
          <div className="bg-white rounded-lg shadow-sm border border-purple-100 p-6">
            <h3 className="text-lg font-semibold text-slate-800 mb-4">Price</h3>
            <div>
              <label className="block text-sm font-medium text-slate-700 mb-1">
                Service Price
              </label>
              <div className="relative">
                <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 text-slate-400 h-4 w-4" />
                <input
                  type="text"
                  value={formData.price}
                  onChange={(e) => setFormData({ ...formData, price: e.target.value })}
                  className="w-full rounded-lg border border-slate-300 pl-9 pr-4 py-2"
                  placeholder="0.00"
                  required
                />
              </div>
            </div>
          </div>

          {/* Status */}
          <div className="bg-white rounded-lg shadow-sm border border-purple-100 p-6">
            <h3 className="text-lg font-semibold text-slate-800 mb-4">Status</h3>
            <select
              value={formData.status}
              onChange={(e) => setFormData({ ...formData, status: e.target.value as JobStatus })}
              className="w-full rounded-lg border border-slate-300 px-3 py-2"
              required
            >
              <option value="Scheduled">Scheduled</option>
              <option value="In Progress">In Progress</option>
              <option value="On Hold">On Hold</option>
              <option value="Completed">Completed</option>
              <option value="Cancelled">Cancelled</option>
            </select>
          </div>

          {/* Recurring Info */}
          {formData.recurring !== 'one-time' && (
            <div className="bg-white rounded-lg shadow-sm border border-purple-100 p-6">
              <div className="flex items-center gap-2 mb-4">
                <Repeat className="h-5 w-5 text-purple-600" />
                <h3 className="text-lg font-semibold text-slate-800">Recurring Job</h3>
              </div>
              <p className="text-sm text-slate-600">
                This job will be automatically scheduled {formData.recurring}.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}