import React, { useState } from 'react';
import { Quote, QuoteItem } from '../../data/quotes';
import { taxRates } from '../../data/tax-rates';
import { paymentTerms } from '../../data/payment-terms';
import { clients } from '../../data/clients';
import { services } from '../../data/services';
import { priceBookItems } from '../../data/price-book';
import { Plus, Trash2, Eye, Search } from 'lucide-react';
import { addDays, format } from 'date-fns';
import { TipTapEditor } from '../editor/TipTapEditor';
import { FileUpload } from '../ui/file-upload';
import { v4 as uuidv4 } from 'uuid';
import { PDFPreview } from '../pdf/PDFPreview';
import { Modal } from '../ui/modal';

interface QuoteFormProps {
  quote?: Quote | null;
  onSubmit: (quote: Partial<Quote>) => void;
  onCancel: () => void;
}

export function QuoteForm({ quote, onSubmit, onCancel }: QuoteFormProps) {
  const [formData, setFormData] = useState<Partial<Quote>>(
    quote || {
      client: '',
      service: '',
      date: format(new Date(), 'yyyy-MM-dd'),
      dueDate: format(addDays(new Date(), 30), 'yyyy-MM-dd'),
      items: [],
      subtotal: 0,
      taxRate: 'us-tx',
      taxAmount: 0,
      discountType: 'percentage',
      discountValue: 0,
      discountAmount: 0,
      total: 0,
      notes: '',
      terms: 'Quote valid for 30 days.',
      paymentTerm: 'net-30',
      status: 'Draft'
    }
  );

  const [showPreview, setShowPreview] = useState(false);
  const [showPriceBook, setShowPriceBook] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const filteredPriceBookItems = priceBookItems.filter(item =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.sku.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const calculateTotals = (items: QuoteItem[], taxRate: string, discountType: 'percentage' | 'fixed', discountValue: number) => {
    const subtotal = items.reduce((sum, item) => sum + item.quantity * item.unitPrice, 0);
    const discountAmount = discountType === 'percentage' 
      ? (subtotal * discountValue) / 100
      : discountValue;
    const taxableAmount = items
      .filter(item => item.taxable)
      .reduce((sum, item) => sum + item.quantity * item.unitPrice, 0);
    const selectedTaxRate = taxRates.find(rate => rate.id === taxRate)?.rate || 0;
    const taxAmount = ((taxableAmount - discountAmount) * selectedTaxRate) / 100;
    const total = subtotal - discountAmount + taxAmount;

    return { subtotal, discountAmount, taxAmount, total };
  };

  const handleAddPriceBookItem = (priceBookItem: any) => {
    const newItem: QuoteItem = {
      id: uuidv4(),
      description: priceBookItem.description,
      quantity: 1,
      unitPrice: priceBookItem.price,
      taxable: true,
      media: priceBookItem.image || null
    };
    const newItems = [...(formData.items || []), newItem];
    const totals = calculateTotals(
      newItems,
      formData.taxRate || 'us-tx',
      formData.discountType || 'percentage',
      formData.discountValue || 0
    );
    setFormData({ ...formData, items: newItems, ...totals });
    setShowPriceBook(false);
  };

  return (
    <form className="max-w-4xl mx-auto space-y-8">
      {/* Header */}
      <div className="flex justify-between items-center">
        <div className="space-y-1">
          <h2 className="text-2xl font-semibold text-slate-800">
            {quote ? 'Edit Quote' : 'New Quote'}
          </h2>
          <p className="text-sm text-slate-600">
            Create a detailed quote for your client
          </p>
        </div>
        <div className="flex gap-3">
          <button
            type="button"
            onClick={() => setShowPreview(true)}
            className="px-4 py-2 text-slate-600 hover:text-slate-800"
          >
            <Eye className="h-4 w-4" />
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
          >
            {quote ? 'Update Quote' : 'Create Quote'}
          </button>
        </div>
      </div>

      {/* Client and Service Selection */}
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-slate-700 mb-1">
            Client
          </label>
          <select
            value={formData.client}
            onChange={(e) => setFormData({ ...formData, client: e.target.value })}
            className="w-full rounded-lg border border-slate-300 px-3 py-2"
            required
          >
            <option value="">Select a client</option>
            {clients.map((client) => (
              <option key={client.id} value={client.name}>
                {client.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-slate-700 mb-1">
            Service
          </label>
          <select
            value={formData.service}
            onChange={(e) => setFormData({ ...formData, service: e.target.value })}
            className="w-full rounded-lg border border-slate-300 px-3 py-2"
            required
          >
            <option value="">Select a service</option>
            {services.map((service) => (
              <option key={service.id} value={service.name}>
                {service.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Line Items */}
      <div>
        <div className="flex justify-between items-center mb-4">
          <h3 className="font-medium text-slate-800">Line Items</h3>
          <div className="flex gap-2">
            <button
              type="button"
              onClick={() => setShowPriceBook(true)}
              className="text-sm text-purple-600 hover:text-purple-700"
            >
              Add from Price Book
            </button>
          </div>
        </div>

        <div className="space-y-4">
          {formData.items?.map((item, index) => (
            <div key={item.id} className="flex gap-4 items-start p-4 bg-slate-50 rounded-lg">
              <div className="flex-1">
                <TipTapEditor
                  content={item.description}
                  onChange={(content) => {
                    const newItems = [...(formData.items || [])];
                    newItems[index] = { ...item, description: content };
                    setFormData({ ...formData, items: newItems });
                  }}
                />
              </div>
              <div className="w-24">
                <input
                  type="number"
                  value={item.quantity}
                  onChange={(e) => {
                    const newItems = [...(formData.items || [])];
                    newItems[index] = { ...item, quantity: parseFloat(e.target.value) };
                    const totals = calculateTotals(
                      newItems,
                      formData.taxRate || 'us-tx',
                      formData.discountType || 'percentage',
                      formData.discountValue || 0
                    );
                    setFormData({ ...formData, items: newItems, ...totals });
                  }}
                  className="w-full rounded-lg border border-slate-300 px-3 py-2"
                  min="1"
                  placeholder="Qty"
                />
              </div>
              <div className="w-32">
                <input
                  type="number"
                  value={item.unitPrice}
                  onChange={(e) => {
                    const newItems = [...(formData.items || [])];
                    newItems[index] = { ...item, unitPrice: parseFloat(e.target.value) };
                    const totals = calculateTotals(
                      newItems,
                      formData.taxRate || 'us-tx',
                      formData.discountType || 'percentage',
                      formData.discountValue || 0
                    );
                    setFormData({ ...formData, items: newItems, ...totals });
                  }}
                  className="w-full rounded-lg border border-slate-300 px-3 py-2"
                  min="0"
                  step="0.01"
                  placeholder="Price"
                />
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={item.taxable}
                  onChange={(e) => {
                    const newItems = [...(formData.items || [])];
                    newItems[index] = { ...item, taxable: e.target.checked };
                    const totals = calculateTotals(
                      newItems,
                      formData.taxRate || 'us-tx',
                      formData.discountType || 'percentage',
                      formData.discountValue || 0
                    );
                    setFormData({ ...formData, items: newItems, ...totals });
                  }}
                  className="rounded border-slate-300 text-purple-600 focus:ring-purple-500"
                />
                <span className="text-sm text-slate-600">Tax</span>
              </div>
              <button
                type="button"
                onClick={() => {
                  const newItems = (formData.items || []).filter((_, i) => i !== index);
                  const totals = calculateTotals(
                    newItems,
                    formData.taxRate || 'us-tx',
                    formData.discountType || 'percentage',
                    formData.discountValue || 0
                  );
                  setFormData({ ...formData, items: newItems, ...totals });
                }}
                className="text-slate-400 hover:text-red-600"
              >
                <Trash2 className="h-4 w-4" />
              </button>
            </div>
          ))}

          <button
            type="button"
            onClick={() => {
              const newItem: QuoteItem = {
                id: uuidv4(),
                description: '',
                quantity: 1,
                unitPrice: 0,
                taxable: true,
                media: ""
              };
              const newItems = [...(formData.items || []), newItem];
              const totals = calculateTotals(
                newItems,
                formData.taxRate || 'us-tx',
                formData.discountType || 'percentage',
                formData.discountValue || 0
              );
              setFormData({ ...formData, items: newItems, ...totals });
            }}
            className="w-full py-3 border-2 border-dashed border-slate-200 rounded-lg text-slate-600 hover:text-slate-700 hover:border-slate-300"
          >
            <Plus className="h-5 w-5 mx-auto" />
          </button>
        </div>
      </div>

      {/* Totals and Settings */}
      <div className="grid grid-cols-2 gap-8">
        <div className="space-y-4">
          <h3 className="font-medium text-slate-800">Notes & Terms</h3>
          <div>
            <label className="block text-sm font-medium text-slate-700 mb-1">
              Notes
            </label>
            <TipTapEditor
              content={formData.notes || ''}
              onChange={(content) => setFormData({ ...formData, notes: content })}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-slate-700 mb-1">
              Terms
            </label>
            <TipTapEditor
              content={formData.terms || ''}
              onChange={(content) => setFormData({ ...formData, terms: content })}
            />
          </div>
        </div>

        <div className="space-y-4">
          <h3 className="font-medium text-slate-800">Summary</h3>
          <div className="bg-slate-50 rounded-lg p-4">
            <div className="space-y-2">
              <div className="flex justify-between text-sm">
                <span className="text-slate-600">Subtotal</span>
                <span className="font-medium">${formData.subtotal?.toFixed(2)}</span>
              </div>

              <div>
                <div className="flex justify-between items-center text-sm">
                  <span className="text-slate-600">Discount</span>
                  <div className="flex items-center gap-2">
                    <select
                      value={formData.discountType}
                      onChange={(e) => {
                        const newType = e.target.value as 'percentage' | 'fixed';
                        const totals = calculateTotals(
                          formData.items || [],
                          formData.taxRate || 'us-tx',
                          newType,
                          formData.discountValue || 0
                        );
                        setFormData({ ...formData, discountType: newType, ...totals });
                      }}
                      className="rounded border-slate-300 text-sm"
                    >
                      <option value="percentage">%</option>
                      <option value="fixed">$</option>
                    </select>
                    <input
                      type="number"
                      value={formData.discountValue}
                      onChange={(e) => {
                        const newValue = parseFloat(e.target.value);
                        const totals = calculateTotals(
                          formData.items || [],
                          formData.taxRate || 'us-tx',
                          formData.discountType || 'percentage',
                          newValue
                        );
                        setFormData({ ...formData, discountValue: newValue, ...totals });
                      }}
                      className="w-20 rounded border-slate-300 text-sm"
                      min="0"
                      step={formData.discountType === 'percentage' ? '1' : '0.01'}
                    />
                  </div>
                </div>
                <div className="flex justify-end text-sm text-slate-500">
                  -${formData.discountAmount?.toFixed(2)}
                </div>
              </div>

              <div>
                <div className="flex justify-between items-center text-sm">
                  <span className="text-slate-600">Tax Rate</span>
                  <select
                    value={formData.taxRate}
                    onChange={(e) => {
                      const totals = calculateTotals(
                        formData.items || [],
                        e.target.value,
                        formData.discountType || 'percentage',
                        formData.discountValue || 0
                      );
                      setFormData({ ...formData, taxRate: e.target.value, ...totals });
                    }}
                    className="rounded border-slate-300 text-sm"
                  >
                    {taxRates.map((rate) => (
                      <option key={rate.id} value={rate.id}>
                        {rate.name} ({rate.rate}%)
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex justify-end text-sm text-slate-500">
                  ${formData.taxAmount?.toFixed(2)}
                </div>
              </div>

              <div className="border-t border-slate-200 pt-2 mt-2">
                <div className="flex justify-between text-base">
                  <span className="font-medium text-slate-700">Total</span>
                  <span className="font-bold text-slate-900">${formData.total?.toFixed(2)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Price Book Modal */}
      <Modal
        isOpen={showPriceBook}
        onClose={() => setShowPriceBook(false)}
        title="Add from Price Book"
      >
        <div className="space-y-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-slate-400" />
            <input
              type="text"
              placeholder="Search items..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full rounded-lg border border-slate-300 pl-9 pr-4 py-2"
            />
          </div>

          <div className="grid grid-cols-1 gap-4 max-h-96 overflow-y-auto">
            {filteredPriceBookItems.map((item) => (
              <button
                key={item.id}
                type="button"
                onClick={() => handleAddPriceBookItem(item)}
                className="flex items-start gap-4 p-4 rounded-lg hover:bg-slate-50 text-left"
              >
                {item.image && (
                  <img
                    src={item.image}
                    alt={item.name}
                    className="w-12 h-12 rounded-lg object-cover"
                  />
                )}
                <div>
                  <h3 className="font-medium text-slate-800">{item.name}</h3>
                  <p className="text-sm text-slate-600">{item.description}</p>
                  <p className="text-sm font-medium text-purple-600 mt-1">
                    ${item.price.toFixed(2)}
                  </p>
                </div>
              </button>
            ))}
          </div>
        </div>
      </Modal>

      {/* PDF Preview Modal */}
      <PDFPreview
        type="quote"
        data={formData}
        isOpen={showPreview}
        onClose={() => setShowPreview(false)}
      />
    </form>
  );
}