import React from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { ArrowLeft, Calendar, User, DollarSign, Receipt, Send, Download, Copy, Eye, Clock } from 'lucide-react';
import { invoices } from '../data/invoices';
import { Breadcrumbs } from '../components/Breadcrumbs';

export default function InvoiceDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const invoice = invoices.find(i => i.id === id);

  if (!invoice) {
    return <div>Invoice not found</div>;
  }

  return (
    <div className="p-6">
      <div className="mb-6">
        <Link to="/invoices" className="inline-flex items-center text-purple-600 hover:text-purple-700 mb-2">
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Invoices
        </Link>
        <Breadcrumbs />
        <div className="mt-4 flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-bold text-slate-800">Invoice Details</h1>
            <p className="text-slate-600">View and manage invoice information</p>
          </div>
          <div className="flex gap-3">
            <button className="inline-flex items-center px-4 py-2 text-slate-600 hover:text-slate-800">
              <Eye className="h-4 w-4 mr-2" />
              Preview
            </button>
            <button className="inline-flex items-center px-4 py-2 text-slate-600 hover:text-slate-800">
              <Copy className="h-4 w-4 mr-2" />
              Duplicate
            </button>
            <button className="inline-flex items-center px-4 py-2 text-slate-600 hover:text-slate-800">
              <Download className="h-4 w-4 mr-2" />
              Download PDF
            </button>
            <button className="inline-flex items-center px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700">
              <Send className="h-4 w-4 mr-2" />
              Send Invoice
            </button>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-2 space-y-6">
          {/* Invoice Information */}
          <div className="bg-white rounded-lg shadow-sm border border-purple-100 overflow-hidden">
            <div className="border-b border-purple-100 px-6 py-4 bg-purple-50">
              <div className="flex items-center gap-2">
                <Receipt className="h-5 w-5 text-purple-600" />
                <h2 className="text-lg font-semibold text-slate-800">Invoice Information</h2>
              </div>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-2 gap-6">
                <div className="space-y-4">
                  <div>
                    <label className="text-sm text-slate-500">Service</label>
                    <p className="font-medium text-slate-800">{invoice.service}</p>
                  </div>
                  <div>
                    <label className="text-sm text-slate-500">Client</label>
                    <div className="flex items-center gap-2">
                      <User className="h-4 w-4 text-slate-400" />
                      <p className="font-medium text-slate-800">{invoice.client}</p>
                    </div>
                  </div>
                  <div>
                    <label className="text-sm text-slate-500">Date</label>
                    <div className="flex items-center gap-2">
                      <Calendar className="h-4 w-4 text-slate-400" />
                      <p className="font-medium text-slate-800">{invoice.date}</p>
                    </div>
                  </div>
                </div>
                <div className="space-y-4">
                  <div>
                    <label className="text-sm text-slate-500">Status</label>
                    <span className={`inline-block mt-1 px-3 py-1 rounded-full text-sm font-medium ${
                      invoice.status === 'Paid'
                        ? 'bg-emerald-100 text-emerald-800'
                        : invoice.status === 'Overdue'
                        ? 'bg-red-100 text-red-800'
                        : 'bg-amber-100 text-amber-800'
                    }`}>
                      {invoice.status}
                    </span>
                  </div>
                  <div>
                    <label className="text-sm text-slate-500">Due Date</label>
                    <div className="flex items-center gap-2">
                      <Clock className="h-4 w-4 text-slate-400" />
                      <p className="font-medium text-slate-800">{invoice.dueDate}</p>
                    </div>
                  </div>
                  <div>
                    <label className="text-sm text-slate-500">Total Amount</label>
                    <div className="flex items-center gap-2">
                      <DollarSign className="h-4 w-4 text-slate-400" />
                      <p className="font-medium text-slate-800">${invoice.total.toFixed(2)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Invoice Items */}
          <div className="bg-white rounded-lg shadow-sm border border-purple-100 overflow-hidden">
            <div className="border-b border-purple-100 px-6 py-4 bg-purple-50">
              <h2 className="text-lg font-semibold text-slate-800">Items</h2>
            </div>
            <div className="p-6">
              <table className="w-full">
                <thead>
                  <tr className="text-left text-sm text-slate-500">
                    <th className="pb-3">Description</th>
                    <th className="pb-3 text-right">Quantity</th>
                    <th className="pb-3 text-right">Unit Price</th>
                    <th className="pb-3 text-right">Amount</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-slate-100">
                  {invoice.items.map((item) => (
                    <tr key={item.id}>
                      <td className="py-3">{item.description}</td>
                      <td className="py-3 text-right">{item.quantity}</td>
                      <td className="py-3 text-right">${item.unitPrice.toFixed(2)}</td>
                      <td className="py-3 text-right">${(item.quantity * item.unitPrice).toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot className="text-sm">
                  <tr>
                    <td colSpan={3} className="pt-4 text-right">Subtotal</td>
                    <td className="pt-4 text-right font-medium">${invoice.subtotal.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td colSpan={3} className="pt-2 text-right text-slate-500">Discount</td>
                    <td className="pt-2 text-right text-slate-500">-${invoice.discountAmount.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td colSpan={3} className="pt-2 text-right text-slate-500">Tax</td>
                    <td className="pt-2 text-right text-slate-500">${invoice.taxAmount.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td colSpan={3} className="pt-4 text-right font-medium">Total</td>
                    <td className="pt-4 text-right font-bold">${invoice.total.toFixed(2)}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>

          {/* Notes & Terms */}
          <div className="bg-white rounded-lg shadow-sm border border-purple-100 overflow-hidden">
            <div className="border-b border-purple-100 px-6 py-4 bg-purple-50">
              <h2 className="text-lg font-semibold text-slate-800">Notes & Terms</h2>
            </div>
            <div className="p-6 space-y-4">
              <div>
                <label className="text-sm text-slate-500">Notes</label>
                <p className="mt-1 text-slate-600">{invoice.notes}</p>
              </div>
              <div>
                <label className="text-sm text-slate-500">Terms & Conditions</label>
                <p className="mt-1 text-slate-600">{invoice.terms}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="space-y-6">
          {/* Quick Actions */}
          <div className="bg-white rounded-lg shadow-sm border border-purple-100 p-6">
            <h3 className="text-lg font-semibold text-slate-800 mb-4">Quick Actions</h3>
            <div className="space-y-3">
              <button className="w-full text-left px-4 py-3 rounded-lg hover:bg-slate-50">
                <p className="font-medium text-slate-800">Mark as Paid</p>
                <p className="text-sm text-slate-600">Record payment received</p>
              </button>
              <button className="w-full text-left px-4 py-3 rounded-lg hover:bg-slate-50">
                <p className="font-medium text-slate-800">Send Reminder</p>
                <p className="text-sm text-slate-600">Send payment reminder</p>
              </button>
              <button className="w-full text-left px-4 py-3 rounded-lg hover:bg-slate-50">
                <p className="font-medium text-slate-800">Add Payment</p>
                <p className="text-sm text-slate-600">Record partial payment</p>
              </button>
            </div>
          </div>

          {/* Payment History */}
          <div className="bg-white rounded-lg shadow-sm border border-purple-100 p-6">
            <h3 className="text-lg font-semibold text-slate-800 mb-4">Payment History</h3>
            {invoice.paymentDate ? (
              <div className="flex items-start gap-3">
                <div className="w-2 h-2 rounded-full bg-emerald-500 mt-2" />
                <div>
                  <p className="text-sm text-slate-800">
                    Payment received via {invoice.paymentMethod}
                  </p>
                  <p className="text-xs text-slate-500">{invoice.paymentDate}</p>
                  <p className="text-xs text-slate-500">Ref: {invoice.paymentReference}</p>
                </div>
              </div>
            ) : (
              <p className="text-sm text-slate-600">No payments recorded yet</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}