import React, { useState } from 'react';
import { CompanyProfile } from '../../data/company-profile';
import { Building2, Mail, Phone, Globe, MapPin, Clock, Palette, Receipt, CreditCard } from 'lucide-react';

interface CompanyProfileFormProps {
  profile: CompanyProfile;
  onSubmit: (profile: CompanyProfile) => void;
}

export function CompanyProfileForm({ profile: initialProfile, onSubmit }: CompanyProfileFormProps) {
  const [profile, setProfile] = useState(initialProfile);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(profile);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6 p-6">
      {/* Company Information */}
      <div className="space-y-4">
        <div className="flex items-center gap-2 pb-2 border-b border-purple-100">
          <Building2 className="h-5 w-5 text-purple-600" />
          <h2 className="text-lg font-semibold text-slate-800">Company Information</h2>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-slate-700 mb-1">
              Company Name
            </label>
            <input
              type="text"
              value={profile.name}
              onChange={(e) => setProfile({ ...profile, name: e.target.value })}
              className="w-full rounded-lg border border-slate-300 px-3 py-2"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-slate-700 mb-1">
              Legal Name
            </label>
            <input
              type="text"
              value={profile.legalName}
              onChange={(e) => setProfile({ ...profile, legalName: e.target.value })}
              className="w-full rounded-lg border border-slate-300 px-3 py-2"
              required
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-slate-700 mb-1">
            Tax ID
          </label>
          <input
            type="text"
            value={profile.taxId}
            onChange={(e) => setProfile({ ...profile, taxId: e.target.value })}
            className="w-full rounded-lg border border-slate-300 px-3 py-2"
            required
          />
        </div>
      </div>

      {/* Contact Information */}
      <div className="space-y-4">
        <div className="flex items-center gap-2 pb-2 border-b border-purple-100">
          <Mail className="h-5 w-5 text-purple-600" />
          <h2 className="text-lg font-semibold text-slate-800">Contact Information</h2>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-slate-700 mb-1">
              Email
            </label>
            <input
              type="email"
              value={profile.email}
              onChange={(e) => setProfile({ ...profile, email: e.target.value })}
              className="w-full rounded-lg border border-slate-300 px-3 py-2"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-slate-700 mb-1">
              Phone
            </label>
            <input
              type="tel"
              value={profile.phone}
              onChange={(e) => setProfile({ ...profile, phone: e.target.value })}
              className="w-full rounded-lg border border-slate-300 px-3 py-2"
              required
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-slate-700 mb-1">
            Website
          </label>
          <input
            type="url"
            value={profile.website}
            onChange={(e) => setProfile({ ...profile, website: e.target.value })}
            className="w-full rounded-lg border border-slate-300 px-3 py-2"
            required
          />
        </div>
      </div>

      {/* Address */}
      <div className="space-y-4">
        <div className="flex items-center gap-2 pb-2 border-b border-purple-100">
          <MapPin className="h-5 w-5 text-purple-600" />
          <h2 className="text-lg font-semibold text-slate-800">Address</h2>
        </div>

        <div>
          <label className="block text-sm font-medium text-slate-700 mb-1">
            Street Address
          </label>
          <input
            type="text"
            value={profile.address.street}
            onChange={(e) => setProfile({
              ...profile,
              address: { ...profile.address, street: e.target.value }
            })}
            className="w-full rounded-lg border border-slate-300 px-3 py-2"
            required
          />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-slate-700 mb-1">
              City
            </label>
            <input
              type="text"
              value={profile.address.city}
              onChange={(e) => setProfile({
                ...profile,
                address: { ...profile.address, city: e.target.value }
              })}
              className="w-full rounded-lg border border-slate-300 px-3 py-2"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-slate-700 mb-1">
              State
            </label>
            <input
              type="text"
              value={profile.address.state}
              onChange={(e) => setProfile({
                ...profile,
                address: { ...profile.address, state: e.target.value }
              })}
              className="w-full rounded-lg border border-slate-300 px-3 py-2"
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-slate-700 mb-1">
              ZIP Code
            </label>
            <input
              type="text"
              value={profile.address.zip}
              onChange={(e) => setProfile({
                ...profile,
                address: { ...profile.address, zip: e.target.value }
              })}
              className="w-full rounded-lg border border-slate-300 px-3 py-2"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-slate-700 mb-1">
              Country
            </label>
            <input
              type="text"
              value={profile.address.country}
              onChange={(e) => setProfile({
                ...profile,
                address: { ...profile.address, country: e.target.value }
              })}
              className="w-full rounded-lg border border-slate-300 px-3 py-2"
              required
            />
          </div>
        </div>
      </div>

      {/* Branding */}
      <div className="space-y-4">
        <div className="flex items-center gap-2 pb-2 border-b border-purple-100">
          <Palette className="h-5 w-5 text-purple-600" />
          <h2 className="text-lg font-semibold text-slate-800">Branding</h2>
        </div>

        <div>
          <label className="block text-sm font-medium text-slate-700 mb-1">
            Logo URL
          </label>
          <input
            type="url"
            value={profile.logo}
            onChange={(e) => setProfile({ ...profile, logo: e.target.value })}
            className="w-full rounded-lg border border-slate-300 px-3 py-2"
            required
          />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-slate-700 mb-1">
              Primary Color
            </label>
            <input
              type="color"
              value={profile.branding.primaryColor}
              onChange={(e) => setProfile({
                ...profile,
                branding: { ...profile.branding, primaryColor: e.target.value }
              })}
              className="w-full h-10 rounded-lg border border-slate-300 px-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-slate-700 mb-1">
              Accent Color
            </label>
            <input
              type="color"
              value={profile.branding.accentColor}
              onChange={(e) => setProfile({
                ...profile,
                branding: { ...profile.branding, accentColor: e.target.value }
              })}
              className="w-full h-10 rounded-lg border border-slate-300 px-2"
            />
          </div>
        </div>
      </div>

      {/* Invoice Settings */}
      <div className="space-y-4">
        <div className="flex items-center gap-2 pb-2 border-b border-purple-100">
          <Receipt className="h-5 w-5 text-purple-600" />
          <h2 className="text-lg font-semibold text-slate-800">Invoice Settings</h2>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-slate-700 mb-1">
              Invoice Prefix
            </label>
            <input
              type="text"
              value={profile.invoiceSettings.prefix}
              onChange={(e) => setProfile({
                ...profile,
                invoiceSettings: { ...profile.invoiceSettings, prefix: e.target.value }
              })}
              className="w-full rounded-lg border border-slate-300 px-3 py-2"
              required
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-slate-700 mb-1">
            Invoice Footer
          </label>
          <textarea
            value={profile.invoiceSettings.footer}
            onChange={(e) => setProfile({
              ...profile,
              invoiceSettings: { ...profile.invoiceSettings, footer: e.target.value }
            })}
            className="w-full rounded-lg border border-slate-300 px-3 py-2"
            rows={2}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-slate-700 mb-1">
            Payment Terms
          </label>
          <textarea
            value={profile.invoiceSettings.terms}
            onChange={(e) => setProfile({
              ...profile,
              invoiceSettings: { ...profile.invoiceSettings, terms: e.target.value }
            })}
            className="w-full rounded-lg border border-slate-300 px-3 py-2"
            rows={2}
          />
        </div>
      </div>

      {/* Bank Details */}
      <div className="space-y-4">
        <div className="flex items-center gap-2 pb-2 border-b border-purple-100">
          <CreditCard className="h-5 w-5 text-purple-600" />
          <h2 className="text-lg font-semibold text-slate-800">Bank Details</h2>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-slate-700 mb-1">
              Account Name
            </label>
            <input
              type="text"
              value={profile.invoiceSettings.bankDetails.accountName}
              onChange={(e) => setProfile({
                ...profile,
                invoiceSettings: {
                  ...profile.invoiceSettings,
                  bankDetails: {
                    ...profile.invoiceSettings.bankDetails,
                    accountName: e.target.value
                  }
                }
              })}
              className="w-full rounded-lg border border-slate-300 px-3 py-2"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-slate-700 mb-1">
              Bank Name
            </label>
            <input
              type="text"
              value={profile.invoiceSettings.bankDetails.bankName}
              onChange={(e) => setProfile({
                ...profile,
                invoiceSettings: {
                  ...profile.invoiceSettings,
                  bankDetails: {
                    ...profile.invoiceSettings.bankDetails,
                    bankName: e.target.value
                  }
                }
              })}
              className="w-full rounded-lg border border-slate-300 px-3 py-2"
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-slate-700 mb-1">
              Account Number
            </label>
            <input
              type="text"
              value={profile.invoiceSettings.bankDetails.accountNumber}
              onChange={(e) => setProfile({
                ...profile,
                invoiceSettings: {
                  ...profile.invoiceSettings,
                  bankDetails: {
                    ...profile.invoiceSettings.bankDetails,
                    accountNumber: e.target.value
                  }
                }
              })}
              className="w-full rounded-lg border border-slate-300 px-3 py-2"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-slate-700 mb-1">
              Routing Number
            </label>
            <input
              type="text"
              value={profile.invoiceSettings.bankDetails.routingNumber}
              onChange={(e) => setProfile({
                ...profile,
                invoiceSettings: {
                  ...profile.invoiceSettings,
                  bankDetails: {
                    ...profile.invoiceSettings.bankDetails,
                    routingNumber: e.target.value
                  }
                }
              })}
              className="w-full rounded-lg border border-slate-300 px-3 py-2"
              required
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-slate-700 mb-1">
            SWIFT Code
          </label>
          <input
            type="text"
            value={profile.invoiceSettings.bankDetails.swift}
            onChange={(e) => setProfile({
              ...profile,
              invoiceSettings: {
                ...profile.invoiceSettings,
                bankDetails: {
                  ...profile.invoiceSettings.bankDetails,
                  swift: e.target.value
                }
              }
            })}
            className="w-full rounded-lg border border-slate-300 px-3 py-2"
            required
          />
        </div>
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
        >
          Save Changes
        </button>
      </div>
    </form>
  );
}