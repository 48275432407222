export type JobStatus = 'Scheduled' | 'In Progress' | 'On Hold' | 'Completed' | 'Cancelled';
export type ViewMode = 'list' | 'board' | 'card' | 'timeline';
export type RecurringType = 'one-time' | 'weekly' | 'monthly' | 'quarterly' | 'yearly';

export interface Task {
  id: string;
  jobId: string;
  content: string;
  completed: boolean;
  order: number;
}

export interface Job {
  id: string;
  clientId: string;
  service: string;
  date: string;
  time: string;
  address: string;
  price: string;
  status: JobStatus;
  photo: string;
  notes: string;
  tasks: Task[];
  recurring: RecurringType;
  statusColor: string;
}

export const getStatusColor = (status: JobStatus) => {
  switch (status) {
    case 'Scheduled':
      return 'bg-blue-100 text-blue-800';
    case 'In Progress':
      return 'bg-amber-100 text-amber-800';
    case 'On Hold':
      return 'bg-purple-100 text-purple-800';
    case 'Completed':
      return 'bg-emerald-100 text-emerald-800';
    case 'Cancelled':
      return 'bg-red-100 text-red-800';
    default:
      return 'bg-slate-100 text-slate-800';
  }
};

export const getRecurringColor = (recurring: RecurringType) => {
  switch (recurring) {
    case 'weekly':
      return 'bg-indigo-100 text-indigo-800';
    case 'monthly':
      return 'bg-pink-100 text-pink-800';
    case 'quarterly':
      return 'bg-cyan-100 text-cyan-800';
    case 'yearly':
      return 'bg-violet-100 text-violet-800';
    default:
      return 'bg-slate-100 text-slate-600';
  }
};

export const jobs: Job[] = [
  {
    id: '1',
    clientId: '1',
    service: 'Plumbing Repair',
    date: '2024-03-15',
    time: '09:00 AM',
    address: '1234 Oak Street, Austin, TX',
    price: '$350',
    status: 'In Progress',
    photo: 'https://images.unsplash.com/photo-1581244277943-fe4a9c777189?w=128&h=128&fit=crop',
    notes: 'Leaking kitchen sink, water damage under cabinet. Need to replace pipe sections and check for additional water damage.',
    tasks: [
      { id: 't1', jobId: '1', content: 'Inspect water damage under sink', completed: true, order: 0 },
      { id: 't2', jobId: '1', content: 'Replace damaged pipe sections', completed: false, order: 1 },
      { id: 't3', jobId: '1', content: 'Test water pressure', completed: false, order: 2 },
    ],
    recurring: 'one-time',
    statusColor: ""
  },
  {
    id: '2',
    clientId: '2',
    service: 'HVAC Maintenance',
    date: '2024-03-15',
    time: '11:00 AM',
    address: '567 Pine Road, Austin, TX',
    price: '$275',
    status: 'Scheduled',
    photo: 'https://images.unsplash.com/photo-1621905252507-b35492cc74b4?w=128&h=128&fit=crop',
    notes: 'Annual maintenance check for central AC unit. Clean filters, check refrigerant levels.',
    tasks: [
      { id: 't4', jobId: '2', content: 'Clean/replace air filters', completed: false, order: 0 },
      { id: 't5', jobId: '2', content: 'Check refrigerant levels', completed: false, order: 1 },
    ],
    recurring: 'quarterly',
    statusColor: ""
  },
  {
    id: '3',
    clientId: '3',
    service: 'Electrical Installation',
    date: '2024-03-16',
    time: '02:00 PM',
    address: '890 Maple Ave, Austin, TX',
    price: '$425',
    status: 'Completed',
    photo: 'https://images.unsplash.com/photo-1621905251189-08b45d6a269e?w=128&h=128&fit=crop',
    notes: 'Install new ceiling fan and light fixture in master bedroom.',
    tasks: [
      { id: 't6', jobId: '3', content: 'Install mounting bracket', completed: true, order: 0 },
      { id: 't7', jobId: '3', content: 'Wire fan and light', completed: true, order: 1 },
      { id: 't8', jobId: '3', content: 'Test operation', completed: true, order: 2 },
    ],
    recurring: 'one-time',
    statusColor: ""
  },
  {
    id: '4',
    clientId: '1',
    service: 'Landscaping',
    date: '2024-03-17',
    time: '08:00 AM',
    address: '1234 Oak Street, Austin, TX',
    price: '$550',
    status: 'Scheduled',
    photo: 'https://images.unsplash.com/photo-1558904541-efa843a96f01?w=128&h=128&fit=crop',
    notes: 'Complete yard overhaul including new flower beds and mulching.',
    tasks: [
      { id: 't9', jobId: '4', content: 'Clear existing vegetation', completed: false, order: 0 },
      { id: 't10', jobId: '4', content: 'Prepare soil', completed: false, order: 1 },
      { id: 't11', jobId: '4', content: 'Plant new flowers', completed: false, order: 2 },
    ],
    recurring: 'monthly',
    statusColor: ""
  },
  {
    id: '5',
    clientId: '2',
    service: 'Window Cleaning',
    date: '2024-03-17',
    time: '10:00 AM',
    address: '567 Pine Road, Austin, TX',
    price: '$180',
    status: 'On Hold',
    photo: 'https://images.unsplash.com/photo-1581578731548-c64695cc6952?w=128&h=128&fit=crop',
    notes: 'Full exterior window cleaning for two-story home.',
    tasks: [
      { id: 't12', jobId: '5', content: 'Clean first floor windows', completed: false, order: 0 },
      { id: 't13', jobId: '5', content: 'Clean second floor windows', completed: false, order: 1 },
    ],
    recurring: 'monthly',
    statusColor: ""
  },
  {
    id: '6',
    clientId: '3',
    service: 'Roof Repair',
    date: '2024-03-18',
    time: '09:00 AM',
    address: '890 Maple Ave, Austin, TX',
    price: '$750',
    status: 'Scheduled',
    photo: 'https://images.unsplash.com/photo-1632778149955-e80f8ceca2e8?w=128&h=128&fit=crop',
    notes: 'Fix roof leak and replace damaged shingles.',
    tasks: [
      { id: 't14', jobId: '6', content: 'Locate and assess damage', completed: false, order: 0 },
      { id: 't15', jobId: '6', content: 'Replace damaged shingles', completed: false, order: 1 },
      { id: 't16', jobId: '6', content: 'Test for water tightness', completed: false, order: 2 },
    ],
    recurring: 'yearly',
    statusColor: ""
  },
];