import React from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Package, Clock, DollarSign, Tag, Edit2, Trash2, Box, Repeat } from 'lucide-react';
import { priceBookItems, PriceBookItem } from '../../data/price-book';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { TipTapEditor } from '../../components/editor/TipTapEditor';

export default function ServiceDetailsPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const item = priceBookItems.find(i => i.id === id);

  if (!item) {
    return <div>Service not found</div>;
  }

  const getTypeIcon = () => {
    switch (item.type) {
      case 'Service':
        return <Clock className="h-5 w-5 text-purple-600" />;
      case 'Material':
        return <Box className="h-5 w-5 text-purple-600" />;
      case 'Product':
        return <Package className="h-5 w-5 text-purple-600" />;
      case 'Subscription':
        return <Repeat className="h-5 w-5 text-purple-600" />;
    }
  };

  const formatPrice = (price: number, unit: string, frequency?: string) => {
    const formattedPrice = price.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    });

    if (item.type === 'Subscription' && frequency) {
      return `${formattedPrice}/${frequency}`;
    }

    switch (unit) {
      case 'per_hour':
        return `${formattedPrice}/hr`;
      case 'per_day':
        return `${formattedPrice}/day`;
      case 'per_unit':
        return formattedPrice;
      case 'per_sqft':
        return `${formattedPrice}/sq.ft`;
      case 'per_project':
        return formattedPrice;
      default:
        return formattedPrice;
    }
  };

  return (
    <div className="p-6">
      <div className="mb-6">
        <Link to="/services" className="inline-flex items-center text-purple-600 hover:text-purple-700 mb-2">
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Services
        </Link>
        <Breadcrumbs />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2 space-y-6">
          {/* Header */}
          <div className="bg-white rounded-lg shadow-sm border border-purple-100">
            <div className="p-6">
              <div className="flex justify-between items-start">
                <div className="flex items-center gap-4">
                  <div className="p-3 bg-purple-100 rounded-lg">
                    {getTypeIcon()}
                  </div>
                  <div>
                    <h1 className="text-2xl font-bold text-slate-800">{item.name}</h1>
                    <p className="text-purple-600">{item.type}</p>
                  </div>
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={() => navigate(`/services/edit/${item.id}`)}
                    className="p-2 text-slate-400 hover:text-purple-600 rounded-lg hover:bg-purple-50"
                  >
                    <Edit2 className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => {
                      // Handle delete
                      navigate('/services');
                    }}
                    className="p-2 text-slate-400 hover:text-red-600 rounded-lg hover:bg-red-50"
                  >
                    <Trash2 className="h-5 w-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Details */}
          <div className="bg-white rounded-lg shadow-sm border border-purple-100">
            <div className="border-b border-purple-100 px-6 py-4 bg-purple-50">
              <h2 className="text-lg font-semibold text-slate-800">Details</h2>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-2 gap-6 mb-6">
                <div>
                  <p className="text-sm text-slate-500 mb-1">SKU</p>
                  <p className="font-medium text-slate-800">{item.sku}</p>
                </div>
                <div>
                  <p className="text-sm text-slate-500 mb-1">Category</p>
                  <p className="font-medium text-slate-800">{item.category}</p>
                </div>
                <div>
                  <p className="text-sm text-slate-500 mb-1">Price</p>
                  <p className="font-medium text-slate-800">
                    {formatPrice(item.price, item.unit, item.subscriptionFrequency)}
                  </p>
                </div>
                {item.type === 'Subscription' && (
                  <div>
                    <p className="text-sm text-slate-500 mb-1">Frequency</p>
                    <p className="font-medium text-slate-800 capitalize">
                      {item.subscriptionFrequency}
                    </p>
                  </div>
                )}
              </div>

              <div className="space-y-4">
                <div>
                  <p className="text-sm text-slate-500 mb-1">Description</p>
                  <p className="text-slate-800">{item.description}</p>
                </div>

                {item.subscriptionDetails && (
                  <div>
                    <p className="text-sm text-slate-500 mb-1">Subscription Details</p>
                    <div className="p-4 bg-purple-50 rounded-lg">
                      <p className="text-slate-800">{item.subscriptionDetails}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Media */}
          {item.image && (
            <div className="bg-white rounded-lg shadow-sm border border-purple-100">
              <div className="border-b border-purple-100 px-6 py-4 bg-purple-50">
                <h2 className="text-lg font-semibold text-slate-800">Media</h2>
              </div>
              <div className="p-6">
                <img
                  src={item.image}
                  alt={item.name}
                  className="w-full h-auto rounded-lg"
                />
              </div>
            </div>
          )}
        </div>

        <div className="space-y-6">
          {/* Quick Stats */}
          <div className="bg-white rounded-lg shadow-sm border border-purple-100 p-6">
            <h3 className="text-lg font-semibold text-slate-800 mb-4">Quick Stats</h3>
            <div className="space-y-4">
              {item.type === 'Material' || item.type === 'Product' ? (
                <>
                  <div>
                    <p className="text-sm text-slate-500">Current Stock</p>
                    <p className="text-2xl font-semibold text-slate-800">
                      {item.inStock || 0} units
                    </p>
                  </div>
                  {item.minStock && (
                    <div>
                      <p className="text-sm text-slate-500">Minimum Stock</p>
                      <p className="text-2xl font-semibold text-slate-800">
                        {item.minStock} units
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div>
                    <p className="text-sm text-slate-500">Active Jobs</p>
                    <p className="text-2xl font-semibold text-slate-800">12</p>
                  </div>
                  <div>
                    <p className="text-sm text-slate-500">Completed Jobs</p>
                    <p className="text-2xl font-semibold text-slate-800">48</p>
                  </div>
                </>
              )}
              <div>
                <p className="text-sm text-slate-500">Revenue (30 days)</p>
                <p className="text-2xl font-semibold text-slate-800">$4,850</p>
              </div>
            </div>
          </div>

          {/* Tags */}
          {item.tags && item.tags.length > 0 && (
            <div className="bg-white rounded-lg shadow-sm border border-purple-100 p-6">
              <h3 className="text-lg font-semibold text-slate-800 mb-4">Tags</h3>
              <div className="flex flex-wrap gap-2">
                {item.tags.map((tag) => (
                  <span
                    key={tag}
                    className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-purple-100 text-purple-800"
                  >
                    <Tag className="h-3 w-3 mr-1" />
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}