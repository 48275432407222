import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Invoice } from '../data/invoices';
import { InvoiceForm } from '../components/invoices/InvoiceForm';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { ArrowLeft, Send, Download, Copy, Eye } from 'lucide-react';

export default function CreateInvoicePage() {
  const navigate = useNavigate();

  const handleSubmit = (data: Partial<Invoice>) => {
    // Handle invoice creation
    navigate('/invoices');
  };

  return (
    <div className="min-h-screen bg-slate-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="mb-6 flex items-center justify-between">
          <div>
            <button
              onClick={() => navigate('/invoices')}
              className="inline-flex items-center text-emerald-600 hover:text-emerald-700 mb-2"
            >
              <ArrowLeft className="h-4 w-4 mr-2" />
              Back to Invoices
            </button>
            <Breadcrumbs />
          </div>
          <div className="flex gap-3">
            <button className="inline-flex items-center px-4 py-2 text-slate-600 hover:text-slate-800">
              <Eye className="h-4 w-4 mr-2" />
              Preview
            </button>
            <button className="inline-flex items-center px-4 py-2 text-slate-600 hover:text-slate-800">
              <Copy className="h-4 w-4 mr-2" />
              Duplicate
            </button>
            <button className="inline-flex items-center px-4 py-2 text-slate-600 hover:text-slate-800">
              <Download className="h-4 w-4 mr-2" />
              Download PDF
            </button>
            <button className="inline-flex items-center px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700">
              <Send className="h-4 w-4 mr-2" />
              Send Invoice
            </button>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-6">
          <div className="col-span-2">
            <div className="bg-white rounded-lg shadow-sm border border-emerald-100 p-6">
              <InvoiceForm
                onSubmit={handleSubmit}
                onCancel={() => navigate('/invoices')}
              />
            </div>
          </div>

          <div className="space-y-6">
            {/* Quick Actions */}
            <div className="bg-white rounded-lg shadow-sm border border-emerald-100 p-6">
              <h3 className="text-lg font-semibold text-slate-800 mb-4">Quick Actions</h3>
              <div className="space-y-3">
                <button className="w-full text-left px-4 py-3 rounded-lg hover:bg-slate-50">
                  <p className="font-medium text-slate-800">Save as Draft</p>
                  <p className="text-sm text-slate-600">Continue editing later</p>
                </button>
                <button className="w-full text-left px-4 py-3 rounded-lg hover:bg-slate-50">
                  <p className="font-medium text-slate-800">Save as Template</p>
                  <p className="text-sm text-slate-600">Reuse for future invoices</p>
                </button>
                <button className="w-full text-left px-4 py-3 rounded-lg hover:bg-slate-50">
                  <p className="font-medium text-slate-800">Schedule Invoice</p>
                  <p className="text-sm text-slate-600">Send at a later date</p>
                </button>
              </div>
            </div>

            {/* Recent Activity */}
            <div className="bg-white rounded-lg shadow-sm border border-emerald-100 p-6">
              <h3 className="text-lg font-semibold text-slate-800 mb-4">Recent Activity</h3>
              <div className="space-y-4">
                <div className="flex items-start gap-3">
                  <div className="w-2 h-2 rounded-full bg-emerald-500 mt-2" />
                  <div>
                    <p className="text-sm text-slate-800">Invoice #1234 paid</p>
                    <p className="text-xs text-slate-500">2 hours ago</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <div className="w-2 h-2 rounded-full bg-amber-500 mt-2" />
                  <div>
                    <p className="text-sm text-slate-800">Invoice #1233 viewed</p>
                    <p className="text-xs text-slate-500">5 hours ago</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Payment Integration */}
            <div className="bg-white rounded-lg shadow-sm border border-emerald-100 p-6">
              <h3 className="text-lg font-semibold text-slate-800 mb-4">Payment Integration</h3>
              <div className="space-y-3">
                <button className="w-full flex items-center justify-between px-4 py-3 rounded-lg hover:bg-slate-50">
                  <div className="flex items-center gap-3">
                    <img src="https://logo.clearbit.com/stripe.com" alt="Stripe" className="w-6 h-6" />
                    <span className="text-slate-800">Stripe</span>
                  </div>
                  <span className="text-emerald-600 text-sm">Connected</span>
                </button>
                <button className="w-full flex items-center justify-between px-4 py-3 rounded-lg hover:bg-slate-50">
                  <div className="flex items-center gap-3">
                    <img src="https://logo.clearbit.com/paypal.com" alt="PayPal" className="w-6 h-6" />
                    <span className="text-slate-800">PayPal</span>
                  </div>
                  <span className="text-blue-600 text-sm">Connect</span>
                </button>
                <button className="w-full flex items-center justify-between px-4 py-3 rounded-lg hover:bg-slate-50">
                  <div className="flex items-center gap-3">
                    <img src="https://logo.clearbit.com/squareup.com" alt="Square" className="w-6 h-6" />
                    <span className="text-slate-800">Square</span>
                  </div>
                  <span className="text-blue-600 text-sm">Connect</span>
                </button>
              </div>
            </div>

            {/* Accounting Integration */}
            <div className="bg-white rounded-lg shadow-sm border border-emerald-100 p-6">
              <h3 className="text-lg font-semibold text-slate-800 mb-4">Sync with Accounting</h3>
              <div className="space-y-3">
                <button className="w-full flex items-center justify-between px-4 py-3 rounded-lg hover:bg-slate-50">
                  <div className="flex items-center gap-3">
                    <img src="https://logo.clearbit.com/quickbooks.com" alt="QuickBooks" className="w-6 h-6" />
                    <span className="text-slate-800">QuickBooks</span>
                  </div>
                  <span className="text-blue-600 text-sm">Connect</span>
                </button>
                <button className="w-full flex items-center justify-between px-4 py-3 rounded-lg hover:bg-slate-50">
                  <div className="flex items-center gap-3">
                    <img src="https://logo.clearbit.com/xero.com" alt="Xero" className="w-6 h-6" />
                    <span className="text-slate-800">Xero</span>
                  </div>
                  <span className="text-blue-600 text-sm">Connect</span>
                </button>
                <button className="w-full flex items-center justify-between px-4 py-3 rounded-lg hover:bg-slate-50">
                  <div className="flex items-center gap-3">
                    <img src="https://logo.clearbit.com/freshbooks.com" alt="FreshBooks" className="w-6 h-6" />
                    <span className="text-slate-800">FreshBooks</span>
                  </div>
                  <span className="text-blue-600 text-sm">Connect</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}