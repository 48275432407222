export const clients = [
  {
    id: '1',
    name: 'Sarah Johnson',
    email: 'sarah.j@example.com',
    phone: '(512) 555-0123',
    address: '1234 Oak Street, Austin, TX',
    clientSince: 'January 2023',
    photo: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=128&h=128&fit=crop',
  },
  {
    id: '2',
    name: 'Michael Chen',
    email: 'michael.c@example.com',
    phone: '(512) 555-0124',
    address: '567 Pine Road, Austin, TX',
    clientSince: 'March 2023',
    photo: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=128&h=128&fit=crop',
  },
  {
    id: '3',
    name: 'Emma Davis',
    email: 'emma.d@example.com',
    phone: '(512) 555-0125',
    address: '890 Maple Ave, Austin, TX',
    clientSince: 'June 2023',
    photo: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?w=128&h=128&fit=crop',
  },
];