import {  gql } from '@apollo/client';
import { apolloClient } from '@/lib/apollo';
import { supabase } from '@/lib/supabase';


export const GET_USER_PROFILE = gql`
  query GetUserProfile($userId: UUID!) {
    profilesCollection(filter: { id: { eq: $userId } }) {
      edges {
        node {
          id
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const GET_USER_SUBSCRIPTION = gql`
  query GetUserSubscription($userId: UUID!) {
    subscriptionsCollection(filter: { userId: { eq: $userId } }) {
      edges {
        node {
          id
          status
          trialEnd
          plan
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const GET_CLIENTS_QUERY = gql`
  query GetClients($companyId: ID!) {
    clientsCollection(filter: { company_id: { eq: $companyId } }) {
      edges {
        node {
          id
          full_name
          email_address
          phone_number
          address
          image_path
        }
      }
    }
  }
`;

export const GET_CLIENT_BY_ID_QUERY = gql`
  query GetClients($id: ID!) {
    clientsCollection(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          full_name
          email_address
          phone_number
          address
          image_path
        }
      }
    }
  }
`;

export const createCompanyIfNotExists = async (userId : any, fullName : any) => {
  try {
    const response = await apolloClient.query({
      query: gql`
        query CHECK_COMPANY_QUERY($ownerId: ID!) {
          companiesCollection(filter: { owner_id: { eq: $ownerId } }) {
            edges {
              node {
                id
              }
            }
          }
        }
      `,
      variables: { ownerId: userId },
    });

    if (response.data.companiesCollection.edges.length === 0) {
      console.log('No companies found. Creating a new company...');

      // Create a new company (you would need to define the mutation for this)
      const createResponse = await apolloClient.mutate({
        mutation: gql`
          mutation CreateClient($input: CreateClientInput!) {
            insertIntocompaniesCollection(objects: $input) {
              records {
                id
              }
            }
          }
        `,
        variables: { 
          input: {
            name: `${fullName}'s company`,
            owner_id: userId,
          }
        },
      });

      const companyId = createResponse.data.insertIntocompaniesCollection.records[0].id

      const { error: updateError } = await supabase.auth.updateUser({
        data: {
          company_id: companyId,
        },
      });
  
      if (updateError) {
        throw new Error(`Failed to update user metadata: ${updateError.message}`);
      }
  
      console.log('User metadata updated with company_id:', companyId);
  
      console.log('Company created:', createResponse);

    } else {
      console.log('Company already exists:', response.data.companiesCollection.edges);
    }

  } catch (error : any) {
    console.error('Error checking or creating company:', error.message);
  }
};