import React, { useState } from 'react';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { CompanyProfileForm } from '../../components/settings/CompanyProfileForm';
import { CompanyProfile, defaultCompanyProfile } from '../../data/company-profile';
import { Building2, ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function CompanyProfilePage() {
  const [profile, setProfile] = useState<CompanyProfile>(defaultCompanyProfile);

  const handleSubmit = (updatedProfile: CompanyProfile) => {
    setProfile(updatedProfile);
    // Here you would typically save to your backend
    alert('Company profile updated successfully!');
  };

  return (
    <div className="p-6">
      <div className="mb-6">
        <Link to="/settings" className="inline-flex items-center text-purple-600 hover:text-purple-700 mb-2">
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Settings
        </Link>
        <Breadcrumbs />
        <div className="mt-4 flex items-center gap-2">
          <Building2 className="h-6 w-6 text-purple-600" />
          <div>
            <h1 className="text-2xl font-bold text-slate-800">Company Profile</h1>
            <p className="text-slate-600">Manage your company information and branding</p>
          </div>
        </div>
      </div>

      <div className="max-w-4xl">
        <div className="bg-white rounded-lg shadow-sm border border-purple-100">
          <CompanyProfileForm profile={profile} onSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  );
}