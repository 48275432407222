import { gql } from '@apollo/client';

export const CREATE_TRIAL_SUBSCRIPTION = gql`
  mutation CreateTrialSubscription($userId: UUID!, $trialEnd: Datetime!) {
    insertIntosubscriptionsCollection(
      objects: [
        {
          userId: $userId
          status: "trialing"
          trialEnd: $trialEnd
        }
      ]
    ) {
      records {
        id
        status
        trialEnd
      }
    }
  }
`;

export const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription($id: UUID!, $status: String!, $plan: String) {
    updatesubscriptionsCollection(
      set: { status: $status, plan: $plan }
      filter: { id: { eq: $id } }
    ) {
      records {
        id
        status
        plan
      }
    }
  }
`;


export const CREATE_CLIENT = gql`
  mutation CreateClient($input: CreateClientInput!) {
    insertIntoclientsCollection(objects: $input) {
      records {
        id
      }
    }
  }
`;

export const UPDATE_CLIENT=gql` 
mutation UpdateClient(
  $set: CreateClientInput!,
  $id: ID!
) {
	updateclientsCollection(
		set: $set,
		filter: {
			id: {
				eq: $id

			}
		},
		atMost:1

	) {
		affectedCount
		records {
			id
			full_name
			email_address
			phone_number
			address
			image_path
		}
	}
}`;


export const DELETE_CLIENT_MUTATION = gql`
  mutation DeleteClient($id: ID!) {
    deleteFromclientsCollection(
      filter: {
        id: { eq: $id } 
      },
      atMost: 1
    ) {
      affectedCount
      records {
        id
        full_name
        email_address
      }
    }
  }`;

export const CREATE_COMPANY = gql`
  mutation CreateClient($input: CreateClientInput!) {
    insertIntocompaniesCollection(objects: $input) {
      records {
        id
      }
    }
  }
`;