import React, { useState } from 'react';
import { Trash2 } from 'lucide-react';
import { AlertDialog } from '@/components/ui/alert-dialog';
import { useMutation } from '@apollo/client';
import { DELETE_CLIENT_MUTATION } from '@/graphql/mutations';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';

interface DeleteClientButtonProps {
  clientId: string;
  clientName: string;
  onDeleted?: () => void;
}

export function DeleteClientButton({ clientId, clientName, onDeleted }: DeleteClientButtonProps) {
  const navigate = useNavigate();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [deleteClient, {error}] = useMutation(DELETE_CLIENT_MUTATION); 

  const handleDelete = async () => {
    try {
      const { data } = await deleteClient({
        variables: {
          id: clientId,
        },
      });

      if (onDeleted) {
        onDeleted();
      }

      toast.success('Client successfully deleted!', {
        description: `${clientName} has been deleted from your clients.`,
        action: {
          label: 'View all clients',
          onClick: () => navigate('/clients'),
        },
      });
    } catch (error) {
      console.error('Error deleting client:', error);
      toast.error('Failed to delete client. Please try again.');
    } finally {
      setShowConfirmDialog(false);
    }
  };


  return (
    <>
      <button
        onClick={() => setShowConfirmDialog(true)}
        className="ml-auto text-red-600"
      >
        <Trash2 className="h-4 w-4" />
      </button>

      <AlertDialog
        isOpen={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={handleDelete}
        title="Delete Client"
        description={`Are you sure you want to delete ${clientName}? This action cannot be undone.`}
        type="danger"
      />
    </>
  );
}